<template>
  <div>出错啦！</div>
</template>

<script>
export default {
  name: "error",
};
</script>

<style>
</style>
